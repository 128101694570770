import collaboratingCompaniesService from '@services/collaborating_companies.service';
import React, { useEffect, useState, useCallback } from 'react';
import { Row, Form, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import CollaboringCompanyModal from './CollaboratingCompanyModal';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import {
  CONSULTANT_ROLE_NAME,
  SHOW_DEPARTMENT_FOR_USER_FORM,
  SHOULD_SHOW_ADMIN_OPTION_FOR_CONSULTANTS
} from '@rd-web-markets/market/dist/constants';
import { CompanyService } from '@services/company.service';
import { SHOULD_SHOW_ADD_USERS_TO_COMPANY, ADD_NEW_USER_TO_COMPANY } from '@rd-web-markets/market/dist/constants';
import AddUserToCompany from '@rd-web-markets/market/dist/user/AddUserToCompany';
import { companyListsService } from '@rd-web-markets/shared/dist/services/lists/company_list.service';
import { ACCOUNT_TYPES_THAT_CAN_ADD_USER_TO_COMPANY } from '@rd-web-markets/market/dist/constants';

const UserForm = ({
  user,
  setUser,
  handleChange
}) => {
  const loggedUser = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
  const [collaboratingCompanies, setCollaboratingCompanies] = useState(null);
  const [companies, setCompanies] = useState(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      const companies = await companyListsService.all();
      setCompanies(companies.companies);
    }
    fetchCompanies();

  }, [])

  

  const getCollaboratingCompanies = useErrorHandling(
    useCallback(async () => {
      const collaboratingCompanies = await collaboratingCompaniesService.all();
      setCollaboratingCompanies(collaboratingCompanies);
    }, [setCollaboratingCompanies])
  );

  useEffect(() => {
    getCollaboratingCompanies();
  }, [getCollaboratingCompanies]);

  const changeCollaborator = (e) => {
    if (e.target.value !== '0') {
      handleChange(e);
    } else {
      setShowCollaboratorModal(true);
    }
  }
  
  const shouldShowAdminOption = loggedUser.account_type === CONSULTANT_ROLE_NAME
    ? SHOULD_SHOW_ADMIN_OPTION_FOR_CONSULTANTS
    : true;

  if (!collaboratingCompanies || !companies) return <Loading />

  return (
    <>
      <CollaboringCompanyModal
        show={showCollaboratorModal}
        onHide={() => setShowCollaboratorModal(false)}
        onSubmit={() => getCollaboratingCompanies()} />
      <Form>
        <Form.Group as={Row}>
          <Form.Label column="md" md={3}>
            {t('first_name')}*
          </Form.Label>
          <Col>
            <Form.Control
              value={user.first_name}
              name="first_name"
              onChange={handleChange}
              size="md"
              type="text"
              placeholder="required"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column="md" md={3}>
            {t('middle_name')}
          </Form.Label>
          <Col>
            <Form.Control
              value={user.middle_name}
              name="middle_name"
              onChange={handleChange}
              size="md"
              type="text"
              placeholder="optional"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column="md" md={3}>
            {t('last_name')}*
          </Form.Label>
          <Col>
            <Form.Control
              value={user.last_name}
              name="last_name"
              onChange={handleChange}
              size="md"
              type="text"
              placeholder="required"
            />
          </Col>
        </Form.Group>
        {(ACCOUNT_TYPES_THAT_CAN_ADD_USER_TO_COMPANY.includes(user.account_type) && ADD_NEW_USER_TO_COMPANY && SHOULD_SHOW_ADD_USERS_TO_COMPANY) && (
          <AddUserToCompany user={user} setUser={setUser} companies={companies} />
        )}
        <Form.Group as={Row}>
          <Form.Label column md={3}>
            {t('email_address')}*
          </Form.Label>
          <Col>
            <Form.Control
              value={user.email}
              onChange={handleChange}
              name="email"
              size="md"
              type="text"
              placeholder="doe@transmetrics.com"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column md={3}>
            {t('phone_number')}
          </Form.Label>
          <Col>
            <Form.Control
              value={user.phone_number || ''}
              name="phone_number"
              onChange={handleChange}
              size="md"
              type="text"
              placeholder={process.env.PUBLIC_URL == '/be' ? '+32XXXXXXXX' : ''}
            />
          </Col>
        </Form.Group>
        { !user.id &&
          <Form.Group as={Row}>
            <Form.Label column md={3}>
              {t('account_type')}*
            </Form.Label>
            <Col>
              <Form.Control
                value={user.account_type}
                onChange={handleChange}
                name="account_type"
                as="select"
                size="md"
              >
                <option value={null}></option>
                <option value="customer">{t('client')}</option>
                <option value={CONSULTANT_ROLE_NAME}>{t('consultant')}</option>
                {shouldShowAdminOption && <option value="admin">{t('admin')}</option>}
              </Form.Control>
            </Col>
          </Form.Group>
        }
        {
          user.account_type === 'accountant' &&
          <Form.Group as={Row}>
            <Form.Label column="md" md={3}>
              {t('company_collaborator')}*
            </Form.Label>
            <Col>
                <Form.Control
                  value={user.collaborating_company_id}
                  onChange={e => changeCollaborator(e)}
                  name="collaborating_company_id"
                  as="select"
                  size="md"
                >
                  <option value={null}></option>
                  {collaboratingCompanies.map(cc => <option key={cc.id} value={cc.id}>{cc.name}</option>)}
                  <option key={0} value={0}>Add new</option>
                </Form.Control>
              </Col>
          </Form.Group>
        }
        { SHOW_DEPARTMENT_FOR_USER_FORM &&
          <Form.Group as={Row}>
            <Form.Label column md={3}>
              {t('department')}
            </Form.Label>
            <Col>
              <Form.Control
                value={user.department || ''}
                name="department"
                onChange={handleChange}
                size="md"
                type="text"
                placeholder="Department"
              />
            </Col>
          </Form.Group>
        }
        <Form.Group as={Row}>
          <Form.Label column md={3}>
            {t('role')}
          </Form.Label>
          <Col>
            <Form.Control
              value={user.role || ''}
              name="role"
              onChange={handleChange}
              size="md"
              type="text"
              placeholder="Consultant/ Dev / etc"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column md={3}>
            {t('notes')}
          </Form.Label>
          <Col>
            <Form.Control
              as="textarea"
              rows={3}
              value={user.notes || ''}
              name="notes"
              onChange={handleChange}
              size="md"
              type="text"
              placeholder={t('enter_optional_notes_here')}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column md={3}>
            {t('invite_immediately')}
          </Form.Label>
          <Col>
            <Form.Check
              type="checkbox"
              name="invite_immediately"
              checked={user.invite_immediately}
              value={user.invite_immediately}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        { ['admin', 'consultant', 'super_consultant'].includes(user.account_type) &&
          <Form.Group as={Row}>
            <Form.Label column md={3}>
              {t('display_dashboard')}
            </Form.Label>
            <Col>
              <Form.Check
                type="checkbox"
                name="show_dashboard"
                checked={user.show_dashboard}
                value={user.show_dashboard}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
        }
        {
          loggedUser.account_type === 'admin' &&
          <Form.Group as={Row}>
            <Form.Label column md={3}>
              {t('account_type')}*
            </Form.Label>
            <Col>
              <Form.Control
                value={user.account_type}
                onChange={handleChange}
                name="account_type"
                as="select"
                size="md"
              >
                <option value="admin">{t('Admin')}</option>
                <option value="consultant">{t('Consultant')}</option>
                <option value="customer">{t('Customer')}</option>
              </Form.Control>
            </Col>
          </Form.Group>
        }
      </Form>
    </>
  )
}

export default UserForm
